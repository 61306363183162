<template>
  <section name="pesquisas">
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de Pesquisas"
          buttonAddItemText="Nova pesquisa"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :callBackModalInsert="redirectToCreateNewPesquisa"
          :dialogDelete.sync="dialogDelete"
        >
          <template slot="sectionInfo">
            <section>
              <v-alert dense dark border="left" color="warning">
                <v-icon color="white" class="mr-2">mdi-alert</v-icon>
                <p class="d-inline">
                  <strong>Atenção!</strong> Um dia antes da avaliação começar, a
                  mesma será <strong>bloqueada para edições</strong> por
                  questões de segurança e integridade.
                </p>
              </v-alert>
            </section>
          </template>
          <template slot="data-table">
            <v-data-table
              :headers="headers"
              :items="pesquisas"
              :loading="loading"
              items-per-page-text="Linhas por página"
              no-results-text="Nenhum registro encontrado"
              loading-text="Carregando... Por favor, espere"
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-text': 'Linhas por página',
              }"
            >
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <!-- Dashboard -->
                    <v-list-item
                      :disabled="validaDataEHoraAtual(item)"
                      @click="irParaDashboard(item)"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :disabled="validaDataEHoraAtual(item)"
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="info" class="actions">
                              mdi-chart-box
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Ir para dashboard</span>
                      </v-tooltip>
                    </v-list-item>
                    <!-- Copiar Link -->
                    <v-list-item :disabled="validaDataEHoraAtual(item)">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :disabled="validaDataEHoraAtual(item)"
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon
                              class="actions"
                              v-clipboard:copy="copyAndPaste(item)"
                              v-clipboard:success="onCopy"
                              v-clipboard:error="onError"
                              color="info"
                              >mdi-link</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Copiar link para formulário da avaliação</span>
                      </v-tooltip>
                    </v-list-item>
                    <!-- Visualizar -->
                    <v-list-item @click="toggleModalLateral(item)">
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon class="actions"> mdi-eye </v-icon>
                            </v-btn>
                          </template>
                          <span>Visualizar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                    <!-- Editar -->
                    <v-list-item :disabled="!validaDataEHoraAtual(item)">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :disabled="!validaDataEHoraAtual(item)"
                            @click="editar(item)"
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon class="actions"> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                    </v-list-item>
                    <!-- Deletar -->
                    <v-list-item @click="remover(item, true)">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon class="actions"> mdi-delete </v-icon>
                          </v-btn>
                        </template>
                        <span>Deletar</span>
                      </v-tooltip>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.titulo`]="{ item }">
                <div class="d-flex flex-row align-center">
                  <p class="mb-0 font-weight-medium mr-2">{{ item.titulo }}</p>
                  <template v-if="item.status == 'A' ? true : false">
                    <template
                      v-for="(statusEvaluation, i) in getStatusOfPlaying(item)"
                    >
                      <v-tooltip
                        :key="i"
                        :color="statusEvaluation.color"
                        bottom
                        v-if="
                          statusEvaluation &&
                          statusEvaluation.color &&
                          statusEvaluation.icon &&
                          statusEvaluation.text
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            :color="statusEvaluation.color"
                          >
                            {{ statusEvaluation.icon }}
                          </v-icon>
                        </template>
                        <span>{{ statusEvaluation.text }}</span>
                      </v-tooltip>
                    </template>
                  </template>
                </div>
              </template>
              <!-- CREATED_AT -->
              <template v-slot:[`item.created_at`]="{ item }">
                <span> {{ item.created_at | dateTimeFormatBr() }}h </span>
              </template>
              <!-- STATUS -->
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  v-if="!validaDataEHoraAtual(item)"
                  :color="item.status == 'A' ? 'success' : 'secondary'"
                >
                  {{ item.status == "A" ? "Ativo" : "Inativo" }}
                </v-chip>
                <v-switch
                  v-else
                  class="mt-0 pt-0"
                  v-model="item.status"
                  @click="mudarStatus(item)"
                  :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                  color="success"
                  value="A"
                  hide-details
                  :loading="loadingStatus[`${item.id}`]"
                ></v-switch>
              </template>
              <template v-slot:[`item.data_hora_inicio`]="{ item }">
                {{ item.data_inicio | dateTimeFormatBr() }}h
              </template>
              <template v-slot:[`item.data_hora_final`]="{ item }">
                {{ item.data_final | dateTimeFormatBr() }}h
              </template>
              <template v-slot:no-data>
                <label>Nenhum registro encontrado</label>
              </template>
              <template v-slot:[`footer.page-text`]="items">
                {{ items.pageStart }} - {{ items.pageStop }} de
                {{ items.itemsLength }}
              </template>
            </v-data-table>
          </template>
          <!--------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">Excluir o registro?</template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn
              :loading="loadingButtonDelete"
              color="error"
              @click="remover()"
            >
              Excluir
            </v-btn>
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
        </Crud>
      </v-col>
    </v-row>
    <ModalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="headerModalLateral"
      :item="modalLateral.item"
    >
      <template v-slot:[`item.descricao`]="{ item }">
        <span>{{ item.descricao | removeTagsOfText() }}</span>
      </template>
      <template v-slot:[`item.devolutivas`]="{ item }">
        <v-chip-group column>
          <v-chip
            small
            v-for="devolutiva in item.devolutivas"
            :key="devolutiva"
          >
            {{ getNameDevolutivas(devolutiva) }}
          </v-chip>
        </v-chip-group>
      </template>
      <template v-slot:[`item.responsavel`]="{ item }">
        <span v-if="item && item.responsavel && item.responsavel.nome">{{
          item.responsavel.nome
        }}</span>
      </template>
      <template v-slot:[`item.data_inicio`]="{ item }">
        <span
          >{{ item.data_inicio | dateFormatBr() }}
          {{ item.hora_inicio | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.data_final`]="{ item }">
        <span
          >{{ item.data_final | dateFormatBr() }}
          {{ item.hora_final | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.modelo`]="{ item }">
        <span v-if="item && item.modelo && item.modelo.nome">{{
          item.modelo.nome
        }}</span>
        <v-chip color="error" dark v-else
          >Está pesquisa não possuí modelo.</v-chip
        >
      </template>
      <template v-slot:[`item.cliente`]="{ item }">
        <span v-if="item && item.cliente && item.cliente.nome">{{
          item.cliente.nome
        }}</span>
        <v-chip color="error" dark v-else
          >Está pesquisa não possuí cliente.</v-chip
        >
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="item.status == 'A' ? 'primary' : 'secondary'"
          small
          dark
        >
          {{ item.status == "A" ? "Ativo" : "Inativo" }}
        </v-chip>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <span
          >{{ item.updated_at | dateFormatBr() }}
          {{ item.updated_at | timeFormatBr() }}h</span
        >
      </template>
    </ModalLateral>
  </section>
</template>

<script>
import logo from "@/assets/images/logos/legiio.png";
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";
import { getLaterDate, getDate } from "@/helpers";

export default {
  name: "PainelPesquisa",
  components: {
    Crud,
    ModalLateral,
  },
  data: () => ({
    logotipo: logo,
    loadingTable: false,
    loadingDelete: [],
    loadingStatus: [],
    tipoImportacao: "",
    SelectItensStatus: [
      { text: "Ativo", value: "A" },
      { text: "Inativo", value: "I" },
    ],
    searchTextField: "",
    headers: [
      { text: "Título", align: "start", value: "titulo" },
      { text: "Data e hora início", align: "start", value: "data_hora_inicio" },
      { text: "Data e hora final", align: "start", value: "data_hora_final" },
      { text: "Data de criação", value: "created_at" },
      { text: "Status", value: "status" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],

    // ModalLateral
    headerModalLateral: [
      { text: "Descrição", value: "descricao" },
      { text: "Responsável", value: "responsavel" },
      { text: "Cliente", value: "cliente" },
      { text: "Modelo", value: "modelo" },
      { text: "Devolutivas", value: "devolutivas" },
      {
        text: "Quantidade de participantes",
        value: "expectativa_de_respondentes",
      },
      { text: "Data e hora de início", value: "data_inicio" },
      { text: "Data e hora de finalização", value: "data_final" },
      { text: "Data de criação", value: "created_at" },
      { text: "Data de atualização", value: "updated_at" },
      { text: "Status", value: "status" },
    ],
    modalLateral: {
      show: false,
      item: [],
      title: "",
    },

    dialogDelete: false,
    loadingButtonDelete: false,
    item: {},
    pesquisa: {
      id: null,
      uuid: null,
      titulo: "",
      id_responsavel: null,
      data_inicio: "",
      hora_inicio: "",
      data_final: "",
      hora_final: "",
      anonimo: "",
      status: "",
    },
    indexEdicao: -1,

    statusOfPlaying: {
      PP: {
        text: "A avaliação começara amanhã.",
        color: "warning",
        icon: "mdi-play-speed",
      },
      P: {
        text: "A avaliação foi iniciada.",
        color: "success",
        icon: "mdi-play-circle-outline",
      },
      F: {
        text: "A avaliação foi finalizada.",
        color: "info",
        icon: "mdi-check",
      },
    },
  }),
  created() {
    this.$store.dispatch("pesquisas/buscarPesquisas");
  },
  computed: {
    pesquisas: {
      get() {
        return this.$store.getters["pesquisas/getPesquisas"];
      },
    },
    loading: {
      get() {
        return this.$store.getters["pesquisas/getLoading"];
      },
      set(value) {
        return value;
      },
    },
    devolutivas: {
      get() {
        return this.$store.getters["pesquisas/getDevolutivas"];
      },
    },
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
    currentDate() {
      const time = new Date().toLocaleTimeString();
      const date = new Date().toLocaleDateString();
      const [day, month, year] = date.split("/");
      const dateTime = `${year}-${month}-${day} ${time}`;
      return dateTime;
    },
    currentTime() {
      const date = new Date();

      const currentTime =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

      return currentTime;
    },
  },
  methods: {
    toggleModalLateral(item) {
      if (this.modalLateral.show == false) {
        this.$store
          .dispatch("pesquisas/fetchAllDataPesquisa", item.uuid)
          .then((response) => {
            this.modalLateral.title = item.titulo;
            this.modalLateral.item = response ? response : [];
            this.modalLateral.show = true;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Houve um erro ao buscar todos os dados da pesquisa.",
              cor: "red",
            });
          });
      } else {
        this.modalLateral.show = false;
      }
    },
    redirectToCreateNewPesquisa() {
      this.$router.push({
        name: "pco-configuracao-pesquisa",
        params: {
          action: "criar",
        },
      });
    },
    editar(item) {
      this.$router.push({
        name: "pco-configuracao-pesquisa",
        params: { action: "editar", uuid: item.uuid },
      });
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = item;
        this.dialogDelete = true;
      } else {
        this.loadingButtonDelete = true;
        const indexItem = this.pesquisas.findIndex(
          (el) => el.id == this.item.id
        );

        this.$store
          .dispatch("pesquisas/deletarPesquisa", this.item.uuid)
          .then((response) => {
            if (response.data === true) {
              this.pesquisas.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Registro removido.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possivel remover o registro.",
            });
          })
          .finally(() => {
            this.loadingButtonDelete = false;
            this.dialogDelete = false;
          });
      }
    },
    getRouter(route) {
      this.$router.push(route);
    },
    mudarStatus(item) {
      this.loadingStatus[item.id] = true;
      this.loadingTable = true;

      this.indexEdicao = this.pesquisas.indexOf(item);
      this.pesquisa = Object.assign({}, item);

      this.pesquisa.status =
        this.pesquisa.status == null ? "I" : this.pesquisa.status;

      let pesquisaItem = this.pesquisa;
      pesquisaItem._method = "PUT";
      pesquisaItem.url = "/pesquisas/" + this.pesquisa.uuid;

      this.$store
        .dispatch("pesquisas/atualizarPesquisaStatus", pesquisaItem)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            Object.assign(this.pesquisas[this.indexEdicao], this.pesquisa);
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Pesquisa atualizado com sucesso.",
              cor: "green",
            });
          }
        })
        .catch((error) => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Erro ao atualizar modelo.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingStatus[item.id] = false;
          this.loadingTable = false;
          this.indexEdicao = -1;
          this.pesquisa = Object.assign(
            {},
            {
              id: null,
              uuid: null,
              titulo: "",
              id_responsavel: null,
              data_inicio: "",
              hora_inicio: "",
              data_final: "",
              hora_final: "",
              anonimo: "",
              status: "",
            }
          );
        });
    },
    irParaDashboard(item) {
      this.$router.push(`/pco/dashboard/${item.uuid}`);
    },
    copyAndPaste(item) {
      const url =
        window.location.host +
        this.$router.resolve({
          path: "/pco/externo/pesquisas/formulario/" + item.uuid,
        }).href;

      return url;
    },
    onCopy: function () {
      this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
        visibilidade: true,
        mensagem: "Link copiado com sucesso!",
        cor: "green",
      });
    },
    onError: function (e) {
      console.log("Erro ao copiar: ", e);
    },
    resolveStatisticsIconVariation(data) {
      if (data === "Concluidas")
        return { icon: "mdi-account-multiple-check", color: "primary" };
      if (data === "A fazer")
        return { icon: "mdi-account-edit", color: "success" };
      if (data === "Pendentes")
        return { icon: "mdi-account-question", color: "warning" };
      if (data === "Invalidas")
        return { icon: "mdi-account-multiple-remove", color: "error" };

      return { icon: "mdi-account-outline", color: "success" };
    },
    async fetchAllData(uuid) {
      const data = await this.$store.dispatch("pesquisas/fetchAllData", uuid);

      return data;
    },
    /**
     * @description Valida data e hora atual para então permitir ou bloquear a edição nas avaliações
     *
     * @param item Object
     */
    validaDataEHoraAtual(item) {
      const currentDate = getLaterDate(1);

      let permitirEdicao = true;
      const itemDataInicio = item.data_inicio.split(" ")[0];

      if (currentDate >= itemDataInicio) permitirEdicao = false;

      return permitirEdicao;
    },
    getNameDevolutivas(id) {
      return this.devolutivas.find((el) => el.id == id).nome;
    },
    /**
     *
     * @description Busca pelo o status do processo em que a avaliação está.
     *
     * @param item Object
     *
     */
    getStatusOfPlaying(item) {
      const currentDate = getDate();
      const laterDate = getLaterDate(1);

      const itemDataInicio = item.data_inicio.split(" ")[0];
      const itemDataFinal = item.data_final.split(" ")[0];

      let result = null;

      if (laterDate == itemDataInicio) result = "PP";
      if (currentDate >= itemDataInicio && currentDate <= itemDataFinal)
        result = "P";
      if (currentDate > itemDataFinal) result = "F";

      return [this.statusOfPlaying[result]];
    },
  },
};
</script>

<style scoped></style>
