import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"name":"pesquisas"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('Crud',{attrs:{"title":"Gestão de Pesquisas","buttonAddItemText":"Nova pesquisa","searchLabelText":"Pesquisar na lista","searchTextField":_vm.searchTextField,"callBackModalInsert":_vm.redirectToCreateNewPesquisa,"dialogDelete":_vm.dialogDelete},on:{"update:searchTextField":function($event){_vm.searchTextField=$event},"update:search-text-field":function($event){_vm.searchTextField=$event},"update:dialogDelete":function($event){_vm.dialogDelete=$event},"update:dialog-delete":function($event){_vm.dialogDelete=$event}}},[_c('template',{slot:"sectionInfo"},[_c('section',[_c(VAlert,{attrs:{"dense":"","dark":"","border":"left","color":"warning"}},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("mdi-alert")]),_c('p',{staticClass:"d-inline"},[_c('strong',[_vm._v("Atenção!")]),_vm._v(" Um dia antes da avaliação começar, a mesma será "),_c('strong',[_vm._v("bloqueada para edições")]),_vm._v(" por questões de segurança e integridade. ")])],1)],1)]),_c('template',{slot:"data-table"},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.pesquisas,"loading":_vm.loading,"items-per-page-text":"Linhas por página","no-results-text":"Nenhum registro encontrado","loading-text":"Carregando... Por favor, espere","footer-props":{
              showFirstLastPage: true,
              'items-per-page-text': 'Linhas por página',
            }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{attrs:{"disabled":_vm.validaDataEHoraAtual(item)},on:{"click":function($event){return _vm.irParaDashboard(item)}}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"disabled":_vm.validaDataEHoraAtual(item),"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions",attrs:{"color":"info"}},[_vm._v(" mdi-chart-box ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ir para dashboard")])])],1),_c(VListItem,{attrs:{"disabled":_vm.validaDataEHoraAtual(item)}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"disabled":_vm.validaDataEHoraAtual(item),"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.copyAndPaste(item)),expression:"copyAndPaste(item)",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"}],staticClass:"actions",attrs:{"color":"info"}},[_vm._v("mdi-link")])],1)]}}],null,true)},[_c('span',[_vm._v("Copiar link para formulário da avaliação")])])],1),_c(VListItem,{on:{"click":function($event){return _vm.toggleModalLateral(item)}}},[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions"},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])])],1)],1),_c(VListItem,{attrs:{"disabled":!_vm.validaDataEHoraAtual(item)}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"disabled":!_vm.validaDataEHoraAtual(item),"icon":""},on:{"click":function($event){return _vm.editar(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions"},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1),_c(VListItem,{on:{"click":function($event){return _vm.remover(item, true)}}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions"},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Deletar")])])],1)],1)],1)]}},{key:"item.titulo",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"mb-0 font-weight-medium mr-2"},[_vm._v(_vm._s(item.titulo))]),(item.status == 'A' ? true : false)?[_vm._l((_vm.getStatusOfPlaying(item)),function(statusEvaluation,i){return [(
                        statusEvaluation &&
                        statusEvaluation.color &&
                        statusEvaluation.icon &&
                        statusEvaluation.text
                      )?_c(VTooltip,{key:i,attrs:{"color":statusEvaluation.color,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":statusEvaluation.color}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(statusEvaluation.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(statusEvaluation.text))])]):_vm._e()]})]:_vm._e()],2)]}},{key:"item.created_at",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeFormatBr")(item.created_at))+"h ")])]}},{key:"item.status",fn:function(ref){
                      var item = ref.item;
return [(!_vm.validaDataEHoraAtual(item))?_c(VChip,{attrs:{"color":item.status == 'A' ? 'success' : 'secondary'}},[_vm._v(" "+_vm._s(item.status == "A" ? "Ativo" : "Inativo")+" ")]):_c(VSwitch,{staticClass:"mt-0 pt-0",attrs:{"label":item.status == 'A' ? 'Ativo' : 'Inativo',"color":"success","value":"A","hide-details":"","loading":_vm.loadingStatus[("" + (item.id))]},on:{"click":function($event){return _vm.mudarStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.data_hora_inicio",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeFormatBr")(item.data_inicio))+"h ")]}},{key:"item.data_hora_final",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeFormatBr")(item.data_final))+"h ")]}},{key:"no-data",fn:function(){return [_c('label',[_vm._v("Nenhum registro encontrado")])]},proxy:true},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" de "+_vm._s(items.itemsLength)+" ")]}}],null,true)})],1),_c('template',{slot:"modalDelete/titleModal"},[_vm._v("Excluir o registro?")]),_c('template',{slot:"modalDelete/contentModal"},[_c('p',[_vm._v(" Atenção! Você está prestes a realizar uma ação que não pode ser desfeita. ")]),_c('p',[_vm._v("Você realmente deseja excluir esse registro?")])]),_c('template',{slot:"modalDelete/actionsModal"},[_c(VBtn,{attrs:{"depressed":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Fechar")]),_c(VBtn,{attrs:{"loading":_vm.loadingButtonDelete,"color":"error"},on:{"click":function($event){return _vm.remover()}}},[_vm._v(" Excluir ")]),_c(VSpacer,{staticClass:"d-none d-md-block"})],1)],2)],1)],1),_c('ModalLateral',{attrs:{"show":_vm.modalLateral.show,"hide":_vm.toggleModalLateral,"title":_vm.modalLateral.title,"headers":_vm.headerModalLateral,"item":_vm.modalLateral.item},scopedSlots:_vm._u([{key:"item.descricao",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("removeTagsOfText")(item.descricao)))])]}},{key:"item.devolutivas",fn:function(ref){
                      var item = ref.item;
return [_c(VChipGroup,{attrs:{"column":""}},_vm._l((item.devolutivas),function(devolutiva){return _c(VChip,{key:devolutiva,attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getNameDevolutivas(devolutiva))+" ")])}),1)]}},{key:"item.responsavel",fn:function(ref){
                      var item = ref.item;
return [(item && item.responsavel && item.responsavel.nome)?_c('span',[_vm._v(_vm._s(item.responsavel.nome))]):_vm._e()]}},{key:"item.data_inicio",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormatBr")(item.data_inicio))+" "+_vm._s(_vm._f("timeFormatBr")(item.hora_inicio))+"h")])]}},{key:"item.data_final",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormatBr")(item.data_final))+" "+_vm._s(_vm._f("timeFormatBr")(item.hora_final))+"h")])]}},{key:"item.modelo",fn:function(ref){
                      var item = ref.item;
return [(item && item.modelo && item.modelo.nome)?_c('span',[_vm._v(_vm._s(item.modelo.nome))]):_c(VChip,{attrs:{"color":"error","dark":""}},[_vm._v("Está pesquisa não possuí modelo.")])]}},{key:"item.cliente",fn:function(ref){
                      var item = ref.item;
return [(item && item.cliente && item.cliente.nome)?_c('span',[_vm._v(_vm._s(item.cliente.nome))]):_c(VChip,{attrs:{"color":"error","dark":""}},[_vm._v("Está pesquisa não possuí cliente.")])]}},{key:"item.status",fn:function(ref){
                      var item = ref.item;
return [_c(VChip,{attrs:{"color":item.status == 'A' ? 'primary' : 'secondary',"small":"","dark":""}},[_vm._v(" "+_vm._s(item.status == "A" ? "Ativo" : "Inativo")+" ")])]}},{key:"item.created_at",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormatBr")(item.created_at))+" "+_vm._s(_vm._f("timeFormatBr")(item.created_at))+"h")])]}},{key:"item.updated_at",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormatBr")(item.updated_at))+" "+_vm._s(_vm._f("timeFormatBr")(item.updated_at))+"h")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }